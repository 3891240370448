import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-104f7b1b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tagColumn" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "patternColumn" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "mappingColumn" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "buttonsColumn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmPopup = _resolveComponent("ConfirmPopup")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ConfirmPopup),
    _createElementVNode("td", _hoisted_1, [
      ($setup.editToggle)
        ? (_openBlock(), _createBlock(_component_InputText, {
            key: 0,
            modelValue: $setup.editMappingForm.tag,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.editMappingForm.tag) = $event))
          }, null, 8, ["modelValue"]))
        : (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString($props.dicomMappingConfig.tag), 1))
    ]),
    _createElementVNode("td", _hoisted_3, [
      ($setup.editToggle)
        ? (_openBlock(), _createBlock(_component_InputText, {
            key: 0,
            modelValue: $setup.editMappingForm.pattern,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.editMappingForm.pattern) = $event))
          }, null, 8, ["modelValue"]))
        : (_openBlock(), _createElementBlock("label", _hoisted_4, _toDisplayString($props.dicomMappingConfig.pattern), 1))
    ]),
    _createElementVNode("td", _hoisted_5, [
      ($setup.editToggle)
        ? (_openBlock(), _createBlock(_component_InputText, {
            key: 0,
            modelValue: $setup.editMappingForm.mapping,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.editMappingForm.mapping) = $event))
          }, null, 8, ["modelValue"]))
        : (_openBlock(), _createElementBlock("label", _hoisted_6, _toDisplayString($props.dicomMappingConfig.mapping), 1))
    ]),
    _createElementVNode("td", _hoisted_7, [
      _createVNode(_component_Button, {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (
        $setup.updateMapping(
          $props.dicomMappingConfig.id,
          $props.dicomMappingConfig.tag,
          $props.dicomMappingConfig.pattern,
          $props.dicomMappingConfig.mapping
        )
      )),
        class: "p-button-text flex justify-content-center"
      }, {
        default: _withCtx(() => [
          (!$setup.editToggle)
            ? (_openBlock(), _createBlock(_component_mdicon, {
                key: 0,
                name: "pencil"
              }))
            : (_openBlock(), _createBlock(_component_mdicon, {
                key: 1,
                name: "content-save"
              }))
        ]),
        _: 1
      }),
      _createVNode(_component_Button, {
        onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.deleteMapping($event, $props.dicomMappingConfig.id))),
        class: "p-button-text p-button-danger"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_mdicon, { name: "delete" })
        ]),
        _: 1
      })
    ])
  ], 64))
}