
import OrganizationService from "../../../services/OrganizationService"
import { reactive, ref } from "vue"
import DicomMappingRow from "../dicoms/DicomMappingRow.vue"

export default {
  name: "DicomMappingConfigDetailsDialog",
  props: {
    dicomMappingConfig: {
      type: Object,
      default: null,
    },
  },
  components: { DicomMappingRow },
  emits: ["config-changed"],
  setup(props: any, { emit }: any) {
    const orgsService = OrganizationService.getInstance()
    const form = reactive({
      alias: "",
      target: "",
    })
    const mappings = ref()
    const addMappingForm = reactive({
      tag: "",
      pattern: "",
      mapping: "",
    })

    loadDicomMappingConfig()
    getMappings()

    function getMappings() {
      orgsService.getDicomMappingConfig(props.dicomMappingConfig.id).then((result) => {
        mappings.value = result.data.mappings
      })
    }

    function addMapping() {
      orgsService.addDicomMapping(props.dicomMappingConfig.id, addMappingForm).then(() => {
        getMappings()
      })
    }

    function loadDicomMappingConfig() {
      form.alias = props.dicomMappingConfig.alias
      form.target = props.dicomMappingConfig.target
    }

    function saveChanges() {
      orgsService.updateDicomMappingConfigs(props.dicomMappingConfig.id, form).then(() => {
        emit("config-changed")
      })
    }

    return {
      saveChanges,
      form,
      mappings,
      addMapping,
      addMappingForm,
      getMappings,
    }
  },
}
