
import OrganizationService from "../../../services/OrganizationService"
import { useConfirm } from "primevue/useconfirm"
import { ref, reactive } from "vue"

export default {
  name: "DicomMappingRow",
  props: {
    dicomMappingConfig: {
      type: Object,
      default: null,
    },
  },
  emits: ["mapping-changed"],
  setup(props: any, { emit }: any) {
    const orgsService = OrganizationService.getInstance()
    const confirm = useConfirm()
    const editToggle = ref(false)
    const editMappingForm = reactive({
      tag: "",
      pattern: "",
      mapping: "",
    })

    function deleteMapping(event: any, data: string) {
      confirm.require({
        target: event.currentTarget,
        message: "Do you want to delete this mapping ?",
        accept: () => {
          orgsService.deleteDicomMapping(data).then(() => {
            emit("mapping-changed")
          })
        },
      })
    }

    function updateMapping(
      dataId: string,
      dataTag: string,
      dataPattern: string,
      dataMapping: string
    ) {
      if (editToggle.value) {
        orgsService.updateDicomMapping(dataId, editMappingForm).then(() => {
          editToggle.value = false
          emit("mapping-changed")
        })
      }
      editMappingForm.tag = dataTag
      editMappingForm.pattern = dataPattern
      editMappingForm.mapping = dataMapping
      editToggle.value = !editToggle.value
    }

    return {
      deleteMapping,
      updateMapping,
      editMappingForm,
      editToggle,
    }
  },
}
