
import OrganizationService from "../../../services/OrganizationService"
import { computed, ref } from "vue"
import DicomMappingConfigDetailsDialog from "../dicoms/DicomMappingConfigDetailsDialog.vue"
import { useRoute } from "vue-router"
import { useConfirm } from "primevue/useconfirm"
import AddDicomMappingConfig from "../dicoms/AddDicomMappingConfig.vue"
import PrimaryButton from "@/components/buttons/PrimaryButton.vue"

export default {
  name: "ComputerDicomMappingConfigsList",
  components: { AddDicomMappingConfig, DicomMappingConfigDetailsDialog, PrimaryButton },
  props: {},
  setup() {
    const route = useRoute()
    const orgsService = OrganizationService.getInstance()
    const dicomMappingConfigs = ref()
    const showDialog = ref(false)
    const showAdd = ref(false)
    const selectedConfig = ref()
    const computerId = computed(() => route.params.computer_id as string)
    const organizationId = computed(() => route.params.organization_id as string)
    const confirm = useConfirm()
    const windowWidth = ref(window.innerWidth)
    const scrollable = computed(() => windowWidth.value > 960)

    loadDicomMappingConfigs()

    function loadDicomMappingConfigs() {
      showDialog.value = false
      showAdd.value = false
      orgsService.getDicomMappingConfigsForOrg(organizationId.value).then((result) => {
        dicomMappingConfigs.value = result.data
      })
    }

    function showConfig(configData: any) {
      selectedConfig.value = configData
      showDialog.value = true
    }

    function deleteConfig(event: any, configId: string) {
      confirm.require({
        target: event.currentTarget,
        message: "Do you want to delete this DicomMappingConfig ?",
        accept: () => {
          orgsService.deleteDicomMappingConfig(configId).then(() => {
            loadDicomMappingConfigs()
          })
        },
      })
    }

    let frameUrl = ref("https://vsol.slab.com/embed/dicom-mapping-configuration-k6usy8ny")

    return {
      dicomMappingConfigs,
      showDialog,
      loadDicomMappingConfigs,
      selectedConfig,
      showConfig,
      showAdd,
      computerId,
      deleteConfig,
      scrollable,
      organizationId,
      frameUrl,
    }
  },
}
